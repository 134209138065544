<template>

  <TopMenu :breadcrumbs="breadcrumbs"/>

  <div class="component-filter">
    <div class="range-picker">
      <DatePicker v-model="filter.begin" label="Дата от" :isClear="false" />
      <DatePicker v-model="filter.end" label="Дата до" :isClear="false" />
    </div>
    <div class="btn-group">
      <a class="btn btn--medium btn--default btn--inline" @click="saveFilter($event)">
        <div class="btn--text"> Применить фильтры </div>
        <div class="btn--loader"> </div>
      </a>
      <a class="btn btn--medium btn--outline btn--inline" @click="clearFilter">
        <div class="btn--text"> Сбросить фильтры </div>
        <div class="btn--loader"> </div>
      </a>
    </div>
  </div>

  <div class="load-container" :class="{'load' : isLoad}">
    <template v-if="companies.length > 0">
      <template v-if="companies.length > 1">
        <CollapseContainer v-for="company in companies" :key="company.id" class="collapse--transparent">
          <template v-slot:header>
            <div class="c-collapse-badge"> 
              {{ company.houses.length }} 
            </div>
            {{ company.name }}
          </template>
          <template v-slot:body>
              <HousePreview :house="house" :company="company" v-for="house in company.houses" :key="house.id" :filter="displayFilter"/>
          </template>
        </CollapseContainer>
      </template>
      <template v-else>
        <HousePreview :house="house" :company="companies[0]" v-for="house in companies[0].houses" :key="house.id" :filter="displayFilter"/>
      </template>
    </template>
	</div>

</template>

<script setup>
  import TopMenu from '@/components/TopMenu.vue';
  import HousePreview from '@/components/reports/HousePreview.vue';
  import CollapseContainer from '@/components/ux/CollapseContainer.vue';
  import DatePicker from '@/components/ux/DatePicker.vue';
</script>

<script>

  export default {
    name: 'PageReports',
    data: () => ({
      isLoad: false,
      breadcrumbs: [],

      companies: [],

      filter: {
        begin: null,
        end: null,
      },

      displayFilter: {
        begin: null,
        end: null,
      },

    }),
    
    created () {
      
      this.breadcrumbs = [
        {name: this.$route.meta.name, link: this.$route.matched[0].path, ico: this.$route.meta.ico}
      ];

      this.filter.begin = this.$moment().subtract(7, 'days').format('YYYY-MM-DD');
      this.filter.end = this.$moment().format('YYYY-MM-DD');

      this.loadData();
   
    },

    methods: {

    	beforeRender () {},

    	async loadData () {
        this.isLoad = true;
        const param = this.buildfilter();
        this.companies = await this.$store.dispatch('getReportsCompany', {data: param});
        this.displayFilter = {...this.filter};
        this.isLoad = false;
    	},

      buildfilter () {
        const param = new URLSearchParams({});
        if(this.filter.begin !== null) {
          param.set("begin", this.filter.begin + 'T00:00:00.000000+00:00');
        }
        if(this.filter.end !== null) {
          param.set("end", this.filter.end + 'T23:59:59.999999+00:00');
        }
        return param;
      },

      async clearFilter (e) {
        const btn = e.currentTarget;
        btn.classList.add('btn--load');
        this.filter.begin = this.$moment().subtract(7, 'days').format('YYYY-MM-DD');
        this.filter.end = this.$moment().format('YYYY-MM-DD');
        await this.loadData();
        btn.classList.remove('btn--load');
      },

      async saveFilter (e) {
        const btn = e.currentTarget;
        btn.classList.add('btn--load');
        await this.loadData();
        btn.classList.remove('btn--load');
      },

    },
  }
</script>


<style scoped>
  .block {
    margin-bottom: 10px;
  }
  .collapse:not(:last-child) {
    margin-bottom: 5px;
  }
  .c-collapse-badge {
    background: #E9F1F4;
    height: 30px;
    width: 30px;
    border-radius: 30px;
    font-size: 11px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: 0.05em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    color: var(--cozh-blue);
  }
  .collapse:deep(.collapse__header) {
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #0B2836;
    word-wrap: break-word;
  }


  .component-filter {
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    position: relative;
    margin-bottom: 10px;
  }
  .component-filter__item {
    flex: 1 1 0;
    width: 0;
  }
  .component-filter a {
    width: fit-content;
  }


</style>