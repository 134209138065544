<template>
  <div class="c-card">
    <div class="c-card__left">
      <div class="c-card__image" :style="{backgroundImage:'url(' + mkdImage + ')'}"></div>
    </div>
    <div class="c-card__right">

      <div class="c-card__top">

        <div class="c-card__group">
          <div class="c-card__small"> г. {{ house.city}} </div>
          <div class="c-card__name"> {{ house.address }} </div>
          <router-link class="c-card__link link-default" :to="{name: 'PageReportEmployeessHouse', params: {house_id: house.id}, query: { begin: filter.begin, end: filter.end }}">
            <span>Перейти к отчётам</span>
            <img class="btn--ico btn--ico--right" src="@/assets/img/mark-right-blue.svg">
          </router-link>
        </div>
        
        <div class="c-card__manager" v-if="house.manager !== null">
          <div class="c-card__small"> Управляющий дома </div>
          <div class="c-card__fio"> {{ house.manager.lastName }} {{ house.manager.firstName }} {{ house.manager.middleName }}</div>
          <div class="c-card__phone"> {{ house.manager.contact }} </div>
        </div>

        <div class="c-card__hint"> {{ $moment(filter.begin, 'YYYY-MM-DD').format('DD.MM') + ' — ' + $moment(filter.end, 'YYYY-MM-DD').format('DD.MM') }} </div>

      </div>

      <div class="c-card__bottom">

        <div class="c-card__item">
          <div class="c-card__data">
            <div class="c-card__number"> {{ house.total }} </div>
            <div class="c-card__percent"> {{ house.totalP }}% </div>
          </div>
          <div class="c-card__label"> Всего заданий </div>
        </div>

        <div class="c-card__item c-card__item--blue">
          <div class="c-card__data">
            <div class="c-card__number"> {{ house.done }} </div>
            <div class="c-card__percent"> {{ house.doneP }}% </div>
          </div>
          <div class="c-card__label"> Сделано, всё&nbsp;хорошо </div>
        </div>

        <div class="c-card__item c-card__item--orange">
          <div class="c-card__data">
            <div class="c-card__number"> {{ house.issue }} </div>
            <div class="c-card__percent"> {{ house.issueP }}% </div>
          </div>
          <div class="c-card__label"> Сделано, есть&nbsp;проблема </div>
        </div>

        <div class="c-card__item c-card__item--red">
          <div class="c-card__data">
            <div class="c-card__number"> {{ house.failed }} </div>
            <div class="c-card__percent"> {{ house.failedP }}% </div>
          </div>
          <div class="c-card__label"> Не сделано </div>
        </div>
      
        <div class="c-card__item c-card__item--darkred">
          <div class="c-card__data">
            <div class="c-card__number"> {{ house.expired }} </div>
            <div class="c-card__percent"> {{ house.expiredP }}% </div>
          </div>
          <div class="c-card__label"> Просрочено </div>
        </div>

      </div>
    </div>
  </div>

</template>

<script>
  export default {
    name: 'HouseTrackerPreview',
    props: ['house', 'company', 'filter'],
    computed: {
      mkdImage () {
        return this.house.imgLink && this.house.imgLink !== null ? this.house.imgLink : '/img/plug.png';
      },
    },
  }
</script>

<style scoped>
  .c-card {
    background-color: var(--cozh-white);
    border-radius: 8px;
    padding: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .c-card:not(:last-child) {
    margin-bottom: 5px;
  }
  .c-card__left {
    margin-right: 30px;
  }
  .c-card__right {
    width: 100%;
  }

  .c-card__image {
    width: 240px;
    aspect-ratio: 3/2;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #E9F1F4;
    border-radius: 8px;
    border: 1px solid #d9e4eb;
  }

  .c-card__top {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    position: relative;
  }

  .c-card__bottom {
    border-top: 1px solid var(--cozh-light-grey);
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
  }

  .c-card__item {
    width: 25%;
  }


  .c-card__name {
    font-weight: 500;
    font-size: 18px;
    line-height: 1.2;
    margin-top: 2px;
    margin-bottom: 8px;
  }
  .c-card__link {
    font-size: 14px;
    font-weight: 500;
  }
  .c-card__label {
    font-size: 12px;
    font-weight: 500;
    margin-top: 6px;
    /*white-space: nowrap;*/
  }
  .c-card__number {
    font-size: 24px;
    font-weight: 600;
    line-height: 1;
  }
  .c-card__percent {
    font-size: 10px;
    font-weight: 700;
    margin-left: 5px;
  }
  .c-card__data {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .c-card__item--blue .c-card__data {
    color: var(--cozh-blue);
  }
  .c-card__item--orange .c-card__data {
    color: var(--cozh-orange);
  }
  .c-card__item--red .c-card__data {
    color: var(--cozh-red);
  }
  .c-card__item--darkred .c-card__data {
    color: var(--cozh-dark-red);
  }
  .c-card__group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: calc(200% - 20px);
  }
  .c-card__manager {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: calc(300% + 20px);
  }
  .c-card__hint {
    color: var(--cozh-grey);
    font-size: 11px;
    font-weight: 600;
    white-space: nowrap;
    position: absolute;
    top: 2px;
    right: 0px;
  }
  .c-card__small {
    font-weight: 500;
    font-size: 13px;
    line-height: 1.23;
    opacity: .5;
  }
  .c-card__fio {
    font-size: 16px;
    line-height: 1.25;
    margin-top: 4px;
  }
  .c-card__phone {
    font-weight: 500;
    font-size: 16px;
    line-height: 1;
    color: var(--cozh-blue);
    margin-top: 5px;
  }

</style>